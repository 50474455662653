.performance {
  padding: 10px;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.2);
}

.performance > * {
  margin-bottom: 10px;
}

.performance-select {
  background: rgba(255, 255, 255, 0.8);
}

.performance-btn {
  width: 100px;
  height: 30px;
  background: rgba(255, 255, 255, 0.8);
}

.performance-btn:disabled {
  color: rgba(0, 0, 0, 0.5);
}

.performance-bottom {
  display: flex;
}

.performance-gif {
  background-size: contain;
  background-repeat: no-repeat;
}

.performance-bottom > * {
  flex: 1;
}
