.logger {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 0 10px;
  box-sizing: border-box;
}

.logger > p {
  margin: 10px 2px;
}

.logger-error {
  color: #721c24;
  background-color: #f8d7da;
}

.logger-toggle {
  margin: 10px;
}

.user-agent {
  border-bottom: 1px solid #aaa;
  font-size: 10px;
}
