.idb-app {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.idb-app > * {
  flex: 1 1 auto;
}
