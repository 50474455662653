.storage-abuser {
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  flex-basis: 20%;
  padding: 5px;
  box-sizing: border-box;
}

.storage-abuser > label {
  display: flex;
}

.storage-abuser-btn-group {
  display: flex;
}

.storage-abuser-btn-group > button {
  flex-basis: 100px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 5px;
}
